<template>
  <div class="row m-t-30 m-b-30 mx-auto">
    <div class="col qr text-end">
      <img :src="result.qrBase64Image">
    </div>
    <div class="col text-start qr-data">
      <label>{{ $t("qr.default.admissionControlNumber") }}</label>
      <input v-model="result.entryNo" class="form-control qr-input mt-2" readonly>
      <label class="qr-data-label-name">{{ $t("qr.default.fullName") }}</label>
      <input v-model="result.userName" class="form-control qr-input mt-2" readonly>

    </div>
  </div>

</template>

<script>
import Common from '../assets/jsipclient/common'
import {mapGetters} from 'vuex'

export default {
  name: "Qr",
  data() {
    return {
      result: {},
      errors: [],
      sending: false
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    this.getQr()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getQr()
    }
  },
  methods: {
    async getQr() {
      const user_id = this.$store.getters.config.userId
      const token = this.$store.getters.config.token
      const api = Common.createUserApi(token)

      this.sending = true
      try {
        let jsonObject = await api.usersUserIdQrGetAsync(user_id)
        this.result = jsonObject
        console.log(this.result)
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});

        } else {
          Common.handleAPIError(error)
        }
      } finally {
        this.sending = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 190px;
  height: auto;
  border: 1px solid #D9E5F7;
  padding: 15px;
}

.qr {
  padding-right: 30px;

  &-data {
    padding-left: 30px;

    &-label-name {
      margin-top: 30px;
    }
  }

  &-input {
    height: 48px;
    max-width: 200px;
    background: #EFF2F7 !important;
  }
}

label {
  color: #888888
}

::placeholder {
  color: #888888
}

@media only screen and (max-width: 575.98px) {

  #main {
    overflow: scroll;
  }

  img {
    width: 200px;
    height: auto;
    border: 1px solid #D9E5F7;
    padding: 15px;
  }
  .qr {
    text-align: center !important;
    padding-right: 12px;
    min-width: 100%;

    &-data {
      padding: 2px;
      margin-top: 15px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      max-width: 200px;

      &-label-name {
        margin-top: 15px;
      }
    }
  }

}
</style>
